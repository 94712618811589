.breadcrumbs {
  margin: 0 auto;
  max-width: var(--cc-layout-width-xl);
  list-style-type: none;
  padding: var(--cc-sp-20) var(--cc-sp-24);
  display: flex;
  flex-wrap: wrap;

  li {
    font-weight: var(--cc-weight-medium);
    display: inline;

    a {
      text-decoration: none;
      color: var(--cc-text-secondary-subtle);

      &:hover {
        color: var(--cc-text-primary);
      }
    }

    &::after {
      content: '/';
      margin: 0 var(--cc-sp-12);
      color: var(--cc-text-secondary-subtle);
    }

    &:last-child::after {
      content: '';
    }
  }

  .current {
    a {
      color: var(--cc-text-primary);
    }
  }

  .prev {
    a {
      color: var(--cc-text-secondary-subtle);
    }
  }
}

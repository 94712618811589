.h1 {
  font-size: var(--cc-size-32);
  line-height: var(--cc-height-44);
  font-weight: var(--cc-weight-semibold);
}

.h2 {
  font-size: var(--cc-size-24);
  line-height: var(--cc-height-32);
  font-weight: var(--cc-weight-semibold);
}

.h3 {
  font-size: var(--cc-size-16);
  line-height: var(--cc-height-24);
  font-weight: var(--cc-weight-bold);
}

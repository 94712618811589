:root {
  /* font size */
  --cc-size-11: 11px;
  --cc-size-12: 12px;
  --cc-size-14: 14px;
  --cc-size-16: 16px;
  --cc-size-20: 20px;
  --cc-size-24: 24px;
  --cc-size-32: 32px;
  --cc-size-48: 48px;
  --cc-size-72: 72px;

  /* line height */
  --cc-height-14: 14px;
  --cc-height-18: 18px;
  --cc-height-20: 20px;
  --cc-height-24: 24px;
  --cc-height-32: 32px;
  --cc-height-44: 44px;
  --cc-height-48: 48px;
  --cc-height-72: 72px;
}

:root {
  /* spacing */
  --cc-sp-2: 2px;
  --cc-sp-4: 4px;
  --cc-sp-6: 6px;
  --cc-sp-8: 8px;
  --cc-sp-10: 10px;
  --cc-sp-12: 12px;
  --cc-sp-16: 16px;
  --cc-sp-20: 20px;
  --cc-sp-24: 24px;
  --cc-sp-25: 25px;
  --cc-sp-32: 32px;
  --cc-sp-40: 40px;
  --cc-sp-48: 48px;
  --cc-sp-64: 64px;
}

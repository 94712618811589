.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  position: relative;
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
}

.buttonSubmitContainer {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-duration: 0.2s;
  border-radius: 0.375rem;
  background-color: #064e68;
  color: #fff;
  font-weight: 600;
  font-size: 0.875rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  line-height: 1.5; /* leading-6 */
  padding: 0.75rem 0.875rem; /* px-3 py-2 */
}

.buttonCancelContainer {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-duration: 0.2s;
  font-weight: 600;
  font-size: 0.875rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  line-height: 1.5; /* leading-6 */
  color: #111827; /* text-gray-900 */
  border: 1px solid #d1d5db; /* border and border-gray-300 */
  border-radius: 0.375rem; /* rounded-md */
  padding: 0.75rem 0.875rem; /* px-3 py-2 */
}

.buttonSubmitContainer:disabled {
  pointer-events: none;
  opacity: 0.7;
}

.buttonSubmitContainer:active:hover,
.buttonSubmitContainer:active:focus,
.buttonCancelContainer:active:hover,
.buttonCancelContainer:active:focus {
  animation: button-pop 0s ease-out;
  transform: scale(0.95, 0.97);
}

.buttonCancelContainer:hover {
  background-color: #f3f4f6;
}

@keyframes button-pop {
  0% {
    transform: scale(0.95, 0.98);
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .container {
    padding: 25px 30px;
  }
}
.buttonSubmitContainer {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-duration: 0.2s;
  border-radius: 0.375rem;
  background-color: #064e68;
  color: #fff;
  font-weight: 600;
  font-size: 0.875rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  line-height: 1.5; /* leading-6 */
  padding: 0.75rem 0.875rem; /* px-3 py-2 */
}

.buttonCancelContainer {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-duration: 0.2s;
  font-weight: 600;
  font-size: 0.875rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  line-height: 1.5; /* leading-6 */
  color: #111827; /* text-gray-900 */
  border: 1px solid #d1d5db; /* border and border-gray-300 */
  border-radius: 0.375rem; /* rounded-md */
  padding: 0.75rem 0.875rem; /* px-3 py-2 */
}

.buttonSubmitContainer:disabled {
  pointer-events: none;
  opacity: 0.7;
}

.buttonSubmitContainer:active:hover,
.buttonSubmitContainer:active:focus,
.buttonCancelContainer:active:hover,
.buttonCancelContainer:active:focus {
  animation: button-pop 0s ease-out;
  transform: scale(0.95, 0.97);
}

.buttonCancelContainer:hover {
  background-color: #f3f4f6;
}

@keyframes button-pop {
  0% {
    transform: scale(0.95, 0.98);
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .container {
    padding: 25px 30px;
  }
}

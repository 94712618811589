.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(5, 1fr); */
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding: 26px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.15);
}

@media (max-width: 768px) {
  .gridContainer {
    grid-template-columns: 1fr;
  }
}

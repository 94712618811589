.container {
  height: 404px;
  padding: var(--cc-sp-8);
}

.tooltip {
  display: flex;
  background: var(--cc-bg-secondary);
  border-radius: var(--cc-sp-12);
  padding: var(--cc-sp-12);
  border: 1px solid var(--cc-border-primary);
  position: absolute;
  transform: translate(5%, -50%);
  box-shadow: 0px 4px 48px -12px rgba(42, 42, 47, 0.2);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.tooltipBorder {
  background-color: var(--cc-chart-orange);
  width: var(--cc-sp-4);
  margin-right: var(--cc-sp-8);
  border-radius: var(--cc-sp-64);
}

.tooltipHeader {
  text-align: left;
  border-bottom: var(--cc-sp-8) solid var(--cc-bg-secondary);
}

.tooltipRow {
  background-color: inherit;
  border-bottom: var(--cc-sp-4) solid var(--cc-bg-secondary);
}

.tooltipAmount {
  border-radius: var(--cc-sp-16);
  padding: var(--cc-sp-2) var(--cc-sp-8);
  margin-right: var(--cc-sp-4);
}

.tooltipAmountDefault {
  border: 1px solid var(--cc-border-primary);
  background-color: var(--cc-bg-tertiary);
}

.tooltipAmountSuccess {
  border: 1px solid var(--cc-surface-success);
  color: var(--cc-text-success);
  background-color: var(--cc-surface-success-subtle);
}

.tooltipText {
  color: var(--cc-text-secondary-subtle);
}

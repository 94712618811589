.filter,
.dateRangeFilter,
.filterPanelWrapper,
.filterOption,
.dateFilterOption {
  border: solid 1px var(--cc-border-primary);
  border-radius: var(--cc-sp-12);
  background-color: var(--cc-bg-secondary);
  padding: var(--cc-sp-12);
}

.filterPanelWrapper {
  display: flex;
  flex-direction: column;
  row-gap: var(--cc-sp-12);
  margin-bottom: var(--cc-sp-20);
}

.filterPanel {
  position: relative;
  display: flex;
  column-gap: var(--cc-sp-8);
  font-weight: var(--cc-weight-medium);

  .slash {
    width: 1px;
    height: 32px;
    background-color: var(--cc-border-primary);
  }

  & button {
    display: flex;
    align-items: center;
    column-gap: var(--cc-sp-6);
    padding: var(--cc-sp-6);
    padding-right: var(--cc-sp-12);

    span {
      color: var(--cc-chart-green);
    }
  }
}

.runsFilter {
  right: 14px;
}

.filter,
.dateRangeFilter {
  display: flex;
  row-gap: var(--cc-sp-12);
  flex-direction: column;
  opacity: 1;
  transition: all 0.3s ease;
  position: absolute;
  top: 35px;
  width: 300px;
  height: auto;
  z-index: 3;
  box-shadow: 0px 24px 48px -12px #2a2a2f33;

  .filterOption,
  .dateFilterOption {
    border-radius: var(--cc-sp-8);
    padding: var(--cc-sp-4) var(--cc-sp-12);
    display: flex;
    align-items: flex-start;

    & > div {
      width: 100%;
    }

    .title,
    img {
      font-weight: var(--cc-weight-semibold);
      padding-top: var(--cc-sp-6);
    }

    .title {
      padding: var(--cc-sp-6) var(--cc-sp-8);
    }

    .dateInput {
      & input {
        cursor: pointer;
        padding: var(--cc-sp-6) var(--cc-sp-8);
      }

      & fieldset {
        border: none;
      }
    }

    .select,
    .dateInput {
      svg {
        display: none; // Remove the arrow
      }

      &:hover,
      &.Mui-focused {
        background-color: var(--cc-surface-info-subtle-hover);
      }
    }

    .formControl {
      width: 80%;
      justify-content: space-between;
      flex-direction: row;

      svg {
        width: var(--cc-sp-16);
        height: var(--cc-sp-16);
        color: var(--cc-icon-danger);
      }
    }

    .fullWidth {
      width: 100%;
    }
  }
}

.checkbox {
  height: var(--cc-sp-20);
  width: var(--cc-sp-20);
}

.menuItem {
  column-gap: var(--cc-sp-8);
}

.dateRangeFilter {
  top: 80px;
  padding: 0;
  row-gap: 0;

  .dateInputs {
    display: flex;
    flex-direction: column;
    padding: var(--cc-sp-16);

    div {
      display: flex;
      align-items: center;

      p {
        width: 36px;
      }
      input {
        margin: var(--cc-sp-4);
        margin-left: var(--cc-sp-8);
        padding: var(--cc-sp-8);
        border-radius: var(--cc-sp-8);
        background-color: var(--cc-bg-tertiary);
      }
    }
  }

  .buttonGroup {
    border-top: 1px solid var(--cc-border-primary);
    padding: var(--cc-sp-12) var(--cc-sp-16);
    display: flex;
    column-gap: var(--cc-sp-12);
    flex-direction: row;
    justify-content: flex-end;

    button {
      border-radius: var(--cc-sp-8);
      padding: var(--cc-sp-6) var(--cc-sp-12);

      &.apply {
        background-color: var(--cc-chart-green);
        color: var(--cc-bg-secondary);
      }
    }
  }
}

.filterClosed {
  display: none;
  opacity: 0;
  transition: all 0.3s ease;
}

.filtrationChipWrapper {
  display: flex;
  column-gap: var(--cc-sp-8);
}

.filtrationChipContainer {
  display: flex;
  column-gap: var(--cc-sp-6);
  align-items: baseline;
  padding: var(--cc-sp-2);
  padding-left: var(--cc-sp-12);

  label,
  button {
    font-weight: var(--cc-weight-medium);
  }
  button {
    padding: var(--cc-sp-6) var(--cc-sp-12);
    flex: none;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    gap: var(--cc-sp-4);
  }
}

.chip {
  background-color: var(--cc-bg-tertiary) !important;
  border-radius: var(--cc-sp-8) !important;
  color: var(--cc-text-secondary) !important;
  font-weight: var(--cc-weight-medium) !important;

  svg {
    color: var(--cc-text-secondary-subtle);
    height: var(--cc-height-14);
  }

  .itemsContainer,
  .item {
    display: flex;
    align-items: center;
  }
  .itemsContainer {
    column-gap: var(--cc-sp-6);
  }
  .item {
    border: 1px solid;
    border-radius: var(--cc-sp-8);
    padding: 0 var(--cc-sp-6);

    button {
      height: var(--cc-height-18);
      width: var(--cc-height-18);
    }
  }
}

.defaultChip {
  background-color: transparent !important;

  span {
    padding-left: 0 !important;
  }
}

.popover {
  background-color: var(--cc-bg-secondary);
}

.root {
  margin: 0 auto;

  .rdp-custom-selected {
    font-weight: var(--cc-weight-medium);
    font-size: var(--cc-sp-12);
  }

  table {
    border-collapse: separate;
    border-spacing: 0 var(--cc-sp-2);

    td:first-child {
      border-radius: var(--cc-sp-6) 0 0 var(--cc-sp-6);
    }
    td:last-child {
      border-radius: 0 var(--cc-sp-6) var(--cc-sp-6) 0;
    }
  }
}

.filterButtonGroupWrapper {
  .filterPanel {
    & > button {
      background-color: var(--cc-bg-secondary);
      border-radius: var(--cc-sp-8);
    }
  }
}

.selectMenu {
  max-height: 500px !important;
  border: 1px solid var(--cc-border-primary) !important;
  border-radius: var(--cc-sp-12) !important;
  box-shadow: 0px 4px 48px -12px rgba(42, 42, 47, 0.2) !important;
}

.notchedOutline {
  border: none !important; /* Remove the border */
}

.input {
  padding: 6px 8px !important; /* Padding for the input field */
}

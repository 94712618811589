.exportOptions {
    border: solid 1px var(--cc-border-primary);
    border-radius: var(--cc-sp-12);
    background-color: var(--cc-bg-secondary);
    padding: var(--cc-sp-12);
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: all 0.3s ease;
    position: absolute;
    top: 35px;
    height: auto;
    z-index: 3;
    box-shadow: 0px 24px 48px -12px #2a2a2f33;
    width: 156px;
  
    button {
      padding: var(--cc-sp-12) var(--cc-sp-8);
      font-weight: var(--cc-weight-medium);
  
      &:hover {
        background-color: var(--cc-surface-info-subtle-hover);
        border-radius: var(--cc-sp-6);
        color: var(--cc-chart-green);
      }
      &:active {
        background-color: rgba(var(--cc-chart-green-rgba), 0.32);
      }
    }
  }
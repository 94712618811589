.pageTitle {
  margin: 0 auto;
  max-width: var(--cc-layout-width-xl);
  display: flex;
  padding: var(--cc-sp-20) var(--cc-sp-64);
  flex-direction: column;
  row-gap: var(--cc-sp-20);

  .exportBox {
    font-weight: var(--cc-weight-medium) !important;
    display: flex;
    justify-content: space-between;
    position: relative;

    .exportButton {
      display: flex;
      align-items: center;
      column-gap: var(--cc-sp-6);
      padding: var(--cc-sp-6);
      padding-right: var(--cc-sp-12);
      border-radius: var(--cc-sp-8);
      background-color: var(--cc-bg-secondary);
      border: solid 1px var(--cc-border-primary);
    }

    .dateRangeFilter {
      top: 50px !important;
      right: 0 !important;
    }
  }
}

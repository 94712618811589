:root {
  /* background */
  --cc-bg-primary: #fefcfb;
  --cc-bg-secondary: #ffffff;
  --cc-bg-tertiary: #f6f6f6;
  --cc-bg-invert: #101828;

  /* text */
  --cc-text-primary: #2a2a2f;
  --cc-text-primary-disabled: #a0a0a5;
  --cc-text-secondary: #626266;
  --cc-text-secondary-subtle: #8f8f91;
  --cc-text-danger: #ae2e24;
  --cc-text-warning: #a54800;
  --cc-text-success: #216e4e;
  --cc-text-info: #0055cc;
  --cc-text-brand-green: #386900;

  /* buttons, borders, icons, toasts etc */
  --cc-icon-danger: #c9372c;
  --cc-surface-danger: #e2483d;
  --cc-surface-danger-subtle: #ffeceb;
  --cc-surface-warning: #e56910;
  --cc-surface-warning-subtle: #fff7d6;
  --cc-surface-success: #22a06b;
  --cc-surface-success-disable: #afc399;
  --cc-surface-success-subtle: #dcfff1;
  --cc-surface-info: #1d7afc;
  --cc-surface-info-subtle: #e9f2ff;
  --cc-surface-info-subtle-hover: #ebf0e5;
  --cc-border-primary: #e9e9e9;

  /* charts */
  --cc-chart-green: #386900;
  --cc-chart-green-rgba: 56, 105, 0;
  --cc-chart-orange: #dda138;
}

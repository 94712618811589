.button {
    background-color: var(--light-green);
    color: #FFF;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    margin: 8px;
    transition-duration: 0.2s;
}

.button:hover {
    background-color: rgb(5, 158, 5)
}

.button:active:hover,
.button:active:focus {
    animation: button-pop 0s ease-out;
    transform: scale(0.95, 0.97);
    background-color: rgb(1, 124, 1)
}

.mainLayout {
  margin: 0 auto;
  max-width: var(--cc-layout-width-xl);
}

.divider {
  border-color: var(--cc-border-primary);
}

.mainContainer {
  margin: 0 auto;
  padding: var(--cc-sp-32) var(--cc-sp-64) var(--cc-sp-64);
  max-width: var(--cc-layout-width-xl);

  & section:not(:last-child) {
    margin-bottom: var(--cc-sp-32);
  }
}

.container {
  display: flex;
  align-items: center;
  gap: var(--cc-sp-6);
  color: var(--cc-text-secondary);
}

.legendIndicator {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: var(--cc-chart-orange);
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~react-image-gallery/styles/css/image-gallery.css';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

@import './command-center/theme/index.module.scss';

*,
html,
body {
  font-family: 'Montserrat', sans-serif !important;
}

.main__container {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.main__container {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: auto;
}

@font-face {
  font-family: 'Century Gothic';
  src:
    url('fonts/CenturyGothic.woff2') format('woff2'),
    url('fonts/CenturyGothic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Century Gothic';
  src:
    url('fonts/CenturyGothic-Bold.woff2') format('woff2'),
    url('fonts/CenturyGothic-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html,
body {
  font-family: 'Century Gothic', sans-serif;
  font-size: 14px;
  background-color: #f1f9f6 !important;
}

/* NEW DESIGN */

/* Our design breakpoints are going to be: */
/* xl: 1200px */
/* lg: 992px */
/* md: 768px */
/* sm: 576px */

:root {
  /* color variables */
  --light-green: #48b344;
  --deep-blue: #031832;
  --green-gradient-left: #3b8638;
  --green-gradient-right: #20541e;
  --gradient: radial-gradient(
    47.17% 67.22% at 50% 50%,
    var(--green-gradient-left) 0%,
    var(--green-gradient-right) 100%
  );
  --navbar-gradient-left: #031832;
  --navbar-gradient-right: #052a58;
  --navbar-gradient: radial-gradient(
    circle,
    rgba(3, 24, 50, 1) 0%,
    rgba(15, 69, 136, 1) 50%,
    rgba(3, 24, 50, 1) 100%
  );
  --navbar-box-shadow: 0px 4px 44px 0px rgba(0, 0, 0, 0.05);
  /* Global */
  --body-bg-color: #f1f9f6;
  --layout-max-width: 1265px;
  --layout-width-xl: 1265px;
  --layout-width-lg: 960px;
  --layout-width-md: 720px;
  --layout-width-sm: 540px;
  --layout-width-animation-time: 0.25s;
  --card-gap: 16px;
  /* charts */
  --bar-radius: 32px 32px 0px 0px;
  --bar-gradient-bottom-color: #1b531a;
  --bar-gradient-top-color: #46ab42;
  --bar-color: linear-gradient(
    0deg,
    var(--bar-gradient-bottom-color) 0%,
    var(--bar-gradient-top-color) 100%
  );
  --bar-label-color: #a3a3a3;
  /* Title is the color for things like cards */
  --title-color: #2f2f2f;
  --dark-title-color: #000;
  --green-button-color: #fff;
  --button-radius: 0px 0px 15px 15px;
  --navbar-link-color: #fff;
  --navbar-profile-icon-border: 4px solid #43a640;
  --navbar-active-item-bg: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.87) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  --stat-card-color: #fff;
  --breadcrumb-inactive: #898989;
  --breadcrumb-active: #000;
  --nav-card-icon-background: #f0ffef;
  /* These are the main colors of your text */
  /* span is for something like labels */
  /* while content is for everything else */
  --span-color: #a3a3a3;
  --content-color: #4a4a4a;

  .rdp-root {
    --rdp-accent-color: var(--cc-chart-green);
    --rdp-accent-background-color: var(--cc-surface-info-subtle-hover);
    --rdp-day_button-height: 38px;
    --rdp-day_button-width: 38px;
    --rdp-day-height: 38px;
    --rdp-day-width: 38px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  background-color: var(--body-bg-color);
  font-family: 'Century Gothic', sans-serif;
  font-size: 14px;
}

@keyframes animation-ij0qp9 {
  0% {
    transform: translate3d(0px, 0px, 0px);
  }
  100% {
    transform: translate3d(0px, -20px, 0px);
  }
}

.slowbob-animation {
  animation: 6s ease-in-out 0s infinite alternate none running animation-ij0qp9;
}

#loading-overlay {
  position: fixed;
  top: 0;
}

.material-icons {
  font-family: 'Material Icons' !important;
  vertical-align: middle;
  margin: 0 5px;
}

.material-icons.warning {
  color: #c9c920;
}

/* NEW DESIGN */

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
}

.auth-container {
  /* background-image: linear-gradient(to right top, #f0fff5, #f0fffe, #f5ffff, #fcfeff, #ffffff);    */
  margin: 0;
  max-width: 100%;
  border: none;
  min-height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 !important;
}

#grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.rundetails-note-title {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#spray-box {
  position: absolute;
  left: 0;
  right: 0;
  top: -95px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 1em;
  justify-content: space-between;
  align-items: center;
}

.spray-summary {
  text-align: left;
  /* font-weight: 600; */
  text-transform: capitalize;
}

.custom-select {
  padding: 8px 24px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.custom-select:focus {
  outline: none;
  border-color: #66afe9; /* Change color on focus */
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25); /* Add focus effect */
}

.link-tractor {
  border: 1px #cecece solid;
  border-radius: 20px;
  box-shadow: 1px 17px 49px -18px rgba(133, 213, 54, 0.38);
  -webkit-box-shadow: 1px 17px 49px -18px rgba(133, 213, 54, 0.38);
  -moz-box-shadow: 1px 17px 49px -18px rgba(133, 213, 54, 0.38);
}

#spray-logo {
  z-index: 2;
  width: 250px;
}

.tractorDetailsDiv {
  background-color: black;
  border: solid purple 4px;
  display: flex;
  width: 100%;
}

.disabled {
  pointer-events: none;
  filter: grayscale(100%);
}

.data-container-spray {
  box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.tractor-details-cards {
  box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.075);
  -webkit-box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.075);
  background-color: white !important;
}

#run-details-note-textarea {
  resize: none;
  padding: 8px;
  font-style: italic;
  width: calc(100% + 45px);
  height: 100%;
  padding-right: 40px;
  /* background-color:rgb(32, 157, 80); */
  color: white;

  background-color: transparent;
  border-left: 1px #9cc099 solid;
  z-index: 20;
}

#run-details-note-textarea::-webkit-scrollbar {
  display: none;
}

.stat {
  background-color: rgb(32, 157, 80);
  box-shadow: rgb(12, 117, 54);
}

.navbar-expand-sm {
  margin-bottom: 0 !important;
}

#app-container {
  /* background-image: linear-gradient(to right top, #f0fff5, #f0fffe, #f5ffff, #fcfeff, #ffffff);    */
  margin: 0;
  max-width: 100%;
  border: none;
  min-height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 !important;
}

.App-logo {
  height: 40vmin;
}

.buttonRow {
  /* border: solid orange 2px; */
  display: flex;
  justify-content: center;
}

.button-container {
  /* border: solid red 2px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

#navbar-logo {
  height: 60px;
  width: 120px;
}

.navbar-agtellio-logo {
  width: auto;
  height: 3.5rem;
}

.home-container {
  /* border: solid red 2px; */
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 8em;
}

.home-grid-box {
  border: solid green 2px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.grid-item {
  /* border: 1px rgb(164, 200, 171) solid;
  border-radius: 10px; */
  aspect-ratio: 0;
  background-color: white;
  box-shadow: 1px 1px 10px 0px rgb(137, 210, 172);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid-item:hover {
  box-shadow: 1px 1px 10px 0px rgb(12, 117, 54);
  cursor: pointer;
  opacity: 0.9;
}

.grid-item:focus {
  opacity: 1;
}

#parent-container {
  width: 75%;
  margin: 0 auto; /* It centers horizontally */
  height: 100vh;
}

#center-container {
  height: 105vh;
  display: table-cell;
  vertical-align: middle;
  width: 100vw;
}

#bottom-navigation {
  border-top: 1px red solid;
  bottom: 0;
}

.example-container div {
  background: white;
  border-radius: 30px;
  width: 150px;
  height: 150px;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tractor-list-item {
  /* border: solid red 2px; */
  width: 100%;
}

.tractors-page-container {
  border: solid purple 2px;
  width: 100%;
}

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.navBarDiv a {
  padding-left: 20px;
  padding-right: 20px;
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.style-two {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 128, 0, 0),
    rgba(8, 76, 136, 0.75),
    rgba(0, 128, 0, 0)
  );
}

.griditem-title {
  white-space: nowrap;
}

#tractors-container {
  padding-bottom: 4em;
  width: 100%;
}

#green-layout {
  position: relative;
  background-color: #76a07a;
  /* background-image: url(./images/greenbg.png); */
  background-image: url('./images/agtechheader.png');
  background-position: center center;
  background-size: auto;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 8em;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(126 198 61);
  display: none !important;
}

.image-capture-container {
  display: flex;
  flex-direction: column;
}

#white-layout {
  background-color: #fff;
  border: 1px transparent solid;
  position: relative;
}

#spray-title {
  margin: 0;
  padding: 0;
  text-align: right;
  color: #fff;
}

#spray-box {
  position: absolute;
  left: 0;
  right: 0;
  top: -95px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 1em;
  justify-content: space-between;
  align-items: center;
}

#spray-info-container {
  border-left: 2px #0b6d12 solid;
  padding-left: 15px;
}

#spray-box > img {
  margin-left: 1em;
  width: 70px;
  height: 50px;
  aspect-ratio: 1;
}

#spray-list-container {
  list-style-type: none;
  padding: 0 3.4em;
  margin-top: 8em; /* odd behavior */
  margin-top: 100px; /* odd behavior */
}

.spray-summary b {
  color: #60a71b;
  font-weight: bold;
}

#wave {
  width: calc(100% + 2px);
  left: -1px;
  height: 100px;
  position: absolute;
  background-color: #fff;
  top: -50px;
  border-radius: 30px;
}

.mantine-Accordion-chevron {
  display: none !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
}

.mantine-Accordion-control {
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  border-radius: 25px;
}

.accordion-li-container {
  margin-bottom: 26px;
}

.mantine-Accordion-label {
  padding: 0 !important;
  margin: 0;
  border: 1px #cecece solid;
  border-radius: 20px;
  box-shadow: 1px 17px 49px -18px rgba(133, 213, 54, 0.38);
  -webkit-box-shadow: 1px 17px 49px -18px rgba(133, 213, 54, 0.38);
  -moz-box-shadow: 1px 17px 49px -18px rgba(133, 213, 54, 0.38);
}

#run-details-note-textarea::placeholder {
  color: white;
}

.mantine-Accordion-content {
  border: none !important;
}

.mantine-Accordion-item {
  border: none !important;
}

.accordion-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 2em;
}

.stat-desc {
  font-style: italic;
  color: #eafff2;
}

#spray-img-left {
  position: absolute;
  left: 0;
  top: 0;
  height: 285px;
  width: auto;
  z-index: 0;
}

#spray-img-right {
  position: absolute;
  right: 0;
  top: 0;
  height: 285px;
  width: auto;
  z-index: 0;
}

.smallIcon {
  background-color: black;
  border: solid yellow 2px;
  width: 100%;
}

.rightContainer {
  background-color: black;
  border: solid pink 2px;
  display: flex;
  width: 100%;
}

.stat-value {
  color: rgb(234, 255, 242);
}

.leftSide {
  background-color: black;
  /* border: solid red 2px; */
  width: 100%;
}

.rightSide {
  background-color: black;
  border: solid blue 2px;
  width: 100%;
}

.listitem-content {
  margin: 0;
  padding: 0;
}

.listitem-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw - 95px);
  padding: 0 15px;
  flex-grow: 1;
  color: #000;
}

.listitem-container > p {
  font-weight: bold;
}

.aside {
  font-weight: normal !important;
}

.list-container {
  border: 1px #ccc solid;
  padding: 15px;
}

.back-button {
  color: white;
  background-color: green;
  border: 0.1em solid green;
  padding: 0.5em 2em;
  border-radius: 5px;
  transition: all 0.5s ease;
  cursor: pointer;
  font-size: 14px;
  height: 36px;
}

.back-button:hover {
  letter-spacing: 3px;
  color: green;
  background: white;
}

.detailsDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.custom-btn.big.primary {
  color: #333333 !important;
  border-color: #3da238;
  /* min-height: 56px; */
  /* min-width: 167px; */
  padding: 15px 18px;
  background-color: transparent;
  border-width: 3px;
  border-style: solid;
  line-height: 1;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  transition:
    background-color 0.3s ease-in-out,
    border-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
  vertical-align: middle;
  text-align: center;
  position: relative;
  border-radius: 11px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-btn.big.primary:hover {
  background-color: #3da238;
  border-radius: 12px;
  color: white !important;
}

.data-container-spray {
  box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.tractor-details-cards {
  box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.075);
  -webkit-box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: 1px 8px 61px 3px rgba(0, 0, 0, 0.075);
}

.inter-font {
  font-family: 'Inter', sans-serif;
}

/* .custom-cls div {
  color: red;
} */

#run-details-note-textarea:focus,
#run-details-note-textarea:active {
  outline: none;
}

#save-note {
  position: absolute;
  right: -8px;
  bottom: -13px;
  border: 0.09rem solid white;
  z-index: 21;
}

.stat-title {
  color: #eafff2;
}

#save-note svg {
  color: #fff;
}

.stat.note-container {
  background-color: rgb(32, 157, 80);
  box-shadow: rgb(12, 117, 54);
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
}

.grid-container img {
  height: 150px;
  width: 150px;
}

.tractor-mini-title {
  font-size: 12px;
}

.run-details-container {
}

.font-italic {
  font-style: italic;
  /* font-weight: bold; */
}

.gridtwo {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-template-rows: 1fr;
  grid-gap: 20px;
  width: 50%;
  min-width: 345px;
  max-width: 698px;
}
.gridtwo-item {
  background: grey;
  display: flex;
  aspect-ratio: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  justify-items: center;
  align-items: center;
  align-content: center;
}
.gridtwo-item:before {
  content: '';
  display: block;
  height: 0;
  width: 0;
  /* padding-bottom: calc(9 / 16 * 100%); */
}

.gridtwo-img {
  /* width: 20%;
  max-width: 100px;
  min-width: 25px; */
}

.centurygothic {
  font-family: 'Century Gothic';
}

.centurygothicbold {
  font-family: 'Century Gothic';
  font-weight: bold;
}

/* styles.css */
.row {
  display: flex;
  flex-wrap: wrap;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-screen {
  height: 100vh;
}

.w-screen {
  width: 100vw;
}

.awssld__controls {
  visibility: visible !important;
}

.awssld__bullets {
  display: none;
  /* position: relative !important;
  top: -250px !important;
  margin-top: 10px !important;
  border: solid red 2px; */
}

/* .awssld__wrapper {
   position: static;  
  position: initial !important;
} */

.command-center-container {
  text-align: center;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.custom-forgot-password {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: #3182ce;
  text-decoration: none;
}

.custom-forgot-password:hover {
  text-decoration: underline;
}

.custom-login-btn {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.custom-login-btn-inner {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
  background-color: green;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition-duration: 300ms;
}

.custom-login-btn-inner:hover {
  background-color: #276749;
}

.custom-request-invite {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.custom-invite-link {
  font-size: 0.75rem;
  color: #3182ce;
  text-decoration: none;
}

.custom-invite-link:hover {
  text-decoration: underline;
}

#agtech-header {
  opacity: 0 !important;
}

.login-loader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: #f2f4f5;
}

.sk-flow {
  position: absolute;
  display: flex;
  align-items: center;
}

.sk-flow-dot {
  background-color: #197197b4;
}

.sk-flow,
.sk-bounce .login-welcome {
  transition: all 0.5s ease;
}

.login-welcome {
  color: #197197b4;
  font-family: 'Century Gothic', sans-serif;
  font-weight: bold;
  font-size: 6em;
  position: absolute;
  opacity: 0;
}

.animate-visible {
  opacity: 1;
}

.animate-hidden {
  opacity: 0;
}

.mantine-Carousel-control {
  background-color: darkgreen;
}

.mantine-Carousel-control svg {
  color: white;
}

.mantine-Carousel-indicator {
  background-color: green;
  border: 2px white solid;
}

.icon-chevron-left {
  size: 2.125rem;
  content: '\f137';
}

.icon-chevron-right {
  size: 2.125rem;
  content: '\f137';
}

.carousel-disabled {
  pointer-events: none;
}

.run-details-container .carousel-container {
  max-height: 100% !important;
}

/* Adjust the height of the carousel slides */
.run-details-container .carousel-container .carousel {
  height: auto !important;
  max-height: 100% !important;
}

#list-of-sprayers {
  scroll-margin-top: 40px;
}

#list-of-runs {
  scroll-margin-top: 10px;
}

/************************************/
/*     v     MEDIA QUERIES     v    */
/************************************/

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  #invisible-home-icon {
    display: none;
  }
}

@media (max-width: 1040px) {
  .homeGrid-spacing-icon {
    display: none;
  }
}

@media (max-width: 675px) {
  #spray-box {
    width: 60%;
  }
}

@media (max-width: 1002px) {
  #spray-box img {
    height: 120px;
    width: 120px;
  }
}

@media (min-width: 900px) {
  #spray-box {
    justify-content: center;
  }
}

@media (max-width: 835px) {
  #spray-box {
    width: 60%;
    justify-content: center;
  }
}

@media (max-width: 640px) {
  #spray-box {
    width: 80%;
    justify-content: center;
  }

  .spray-summary {
    white-space: nowrap;
  }
}

#home-button {
  border-color: #fff;
  border: 0;
}

#home-button:hover {
  background-color: transparent !important;
}

#home-button svg {
  color: #fff;
}

.breadcrumbs-container {
  padding-bottom: 5px !important;
  text-transform: lowercase;
}

.breadcrumbs-container .mantine-Text-root {
  color: #05763f !important;
}

#home-button-dark {
  border: 0;
  margin-right: 1em;
}

#home-button-dark:hover {
  background-color: transparent !important;
}

#home-button-dark svg {
  color: #05763f;
}

#logo-ag {
  width: 45% !important;
  max-width: 400px;
  margin: 0 auto;
}

.settingsSidebar {
  /* border: solid red 2px; */
}

@media (max-width: 250px) {
  #spray-box {
    width: 100%;
  }

  #spray-box p {
    font-size: 10px;
  }
}
@media (max-width: 335px) {
  #spray-box {
    width: 90%;
  }
}

@media (max-width: 450px) {
  #spray-box {
    border: solid green 2px;
    width: 70%;
    padding: none;
    justify-content: center;
  }

  #spray-box img {
    display: none;
  }

  #spray-info-container {
    border: none;
  }
}

@media (min-width: 1002px) {
  #spray-box {
    width: 45%;
    justify-content: space-evenly;
  }

  #spray-box img {
    height: 75px;
    width: 110px;
  }

  @media (min-width: 1135px) {
    #spray-box {
      width: 40%;
      justify-content: space-evenly;
    }
  }

  @media (min-width: 1290px) {
    #spray-box img {
      height: 90px;
      width: 125px;
    }
  }

  @media (min-width: 1420px) {
    #spray-box p {
      font-size: 18px;
    }
  }

  @media (min-width: 1600px) {
    #spray-box p {
      font-size: 20px;
    }
  }
}

@media (max-width: 992px) {
  .run-details-container {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .overlay-container {
    padding-top: 10px;
    padding-bottom: 30px;
  }
}

@media (max-width: 530px) {
  #save-note {
    margin-right: 15px;
  }
}

@media (max-width: 1164px) {
  .navbar-agtellio-logo {
    margin-left: 130px;
  }
}

/************************************/
/*     ^     MEDIA QUERIES     ^    */
/************************************/

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotating {
  -webkit-animation: rotating 10s linear infinite;
  -moz-animation: rotating 10s linear infinite;
  -ms-animation: rotating 10s linear infinite;
  -o-animation: rotating 10s linear infinite;
  animation: rotating 10s linear infinite;
}

.statcardTooltip {
  position: absolute;
  bottom: 0px;
  right: -22px;
  z-index: 9999;
}

.margin-separation > span:nth-child(2) {
  margin-right: 8px;
}

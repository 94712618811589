.caption {
  font-size: var(--cc-size-11);
  line-height: var(--cc-height-14);
  font-weight: var(--cc-weight-medium);
}

.caption-medium {
  font-size: var(--cc-size-12);
  line-height: var(--cc-height-18);
  font-weight: var(--cc-weight-medium);
}

.label,
.label-medium .button,
.button-medium,
.body {
  font-size: var(--cc-size-14);
  line-height: var(--cc-height-20);
}

.bodyInput {
  font-size: var(--cc-size-16);
  line-height: var(--cc-height-24);
}

.stat {
  font-size: var(--cc-size-20);
  line-height: var(--cc-height-20);
  font-weight: var(--cc-weight-bold);
}

.start-medium {
  font-size: var(--cc-size-48);
  line-height: var(--cc-height-48);
  font-weight: var(--cc-weight-bold);
}

.stat-large {
  font-size: var(--cc-size-72);
  line-height: var(--cc-height-72);
  font-weight: var(--cc-weight-bold);
}

.disabled {
  color: var(--cc-text-primary-disabled);

  img {
    filter: invert(60%);
  }

  .loadingIcon {
    color: var(--cc-surface-success-disable);
    height: var(--cc-size-14) !important;
    width: var(--cc-size-14) !important;
  }
}

.filter {
  background-color: rgba(var(--cc-chart-green-rgba), 0.08);
  border-radius: var(--cc-sp-8);
  font-weight: var(--cc-weight-medium);

  &:hover {
    background-color: rgba(var(--cc-chart-green-rgba), 0.2) !important;
  }
  &:active {
    background-color: rgba(var(--cc-chart-green-rgba), 0.32) !important;
  }
}

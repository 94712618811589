.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0;
  grid-row-gap: 0px;

  border-radius: 20px;
  background: linear-gradient(173deg, #39ad34 0%, #1e581c 100%);
  position: relative;
}

.statItem:nth-child(4n + 1):nth-last-child(-n + 4),
.statItem:nth-child(4n + 1):nth-last-child(-n + 4) ~ .statItem {
  border-bottom: none;
}

@media (min-width: 1200px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
  }
}

.charClassName > div {
  transform: translateY(-240px) !important;
}

@media (max-width: 992px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
  }

  .statItem:nth-child(3n + 1):nth-last-child(-n + 3),
  .statItem:nth-child(3n + 1):nth-last-child(-n + 3) ~ .statItem {
    border-bottom: none;
  }

  .statItemValue {
    font-size: 28px !important;
  }

  .statItemTitle {
    font-size: 16px !important;
  }

  .statSubtitle {
    font-size: 14px !important;
  }

  .statItem {
    padding: 22px 20px !important;
  }

  .charClassName > div {
    transform: translateY(-204px) !important;
  }
}

@media (max-width: 768px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }

  .statItem:nth-child(2n + 1):nth-last-child(-n + 2),
  .statItem:nth-child(2n + 1):nth-last-child(-n + 2) ~ .statItem {
    border-bottom: none;
  }

  .statItem {
    text-align: center;
  }

  .statItem:last-child:before {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    top: -1px;
    border-top: 1px rgba(255, 255, 255, 0.51) solid;
  }
}

@media (max-width: 530px) {
  .statItem {
    padding: 22px 4px !important;
  }
}

.textArea {
  padding: 22px 20px !important;
}

/* .container:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.51);
  top: 50%;
} */

/* .bokeh {
  position: absolute;
  bottom: -20px;
  right: 0;
  z-index: 1;
} */

.statItem {
  position: relative;
  padding: 26px 24px;
  border-bottom: 1px rgba(255, 255, 255, 0.51) solid;
  text-align: center;
}

.statItemTitle,
.statItemValue,
.statSubtitle {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.statItemTitle {
  margin: 0;
  margin-bottom: 10px;
  pointer-events: none;
}

.statItemValue {
  color: #fff;
  font-size: 33px;
  margin-top: 0;
  margin-bottom: 5px;
}

.statSubtitle {
  font-size: 14px;
  margin: 0;
}

.cardSelect {
  display: grid;
  grid-template-columns: repeat(1, 1fr) !important;
  padding: 22px 20px !important;
  border-radius: 20px;
  background: linear-gradient(173deg, #39ad34 0%, #1e581c 100%);
  position: relative;
  justify-items: center;
}

.cardSelect h1 {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.cardSelect .select {
  background-color: white;
}

.tableWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tableContainer {
    overflow-x: auto;

    .tableCell {
      border-right: 1px solid var(--cc-border-primary);
      padding: var(--cc-sp-8) var(--cc-sp-12) !important;
      font-size: var(--cc-size-12) !important;
      line-height: var(--cc-height-20) !important;
      z-index: 1;
    }

    .link {
      position: sticky;
      width: var(--cc-sp-25);
      right: 0;
      background: var(--cc-bg-secondary);
      border-left: 1px solid var(--cc-border-primary);
    }
  }
}

.paginationContainer {
  padding: var(--cc-sp-6);
  padding-left: var(--cc-sp-12);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pageCounter {
    display: flex;
    align-items: center;

    label {
      &:nth-child(1) {
        font-weight: var(--cc-weight-medium);
      }
      &:nth-child(3) {
        color: var(--cc-text-secondary);
      }
    }

    .textFieldCustom {
      margin-left: var(--cc-sp-6);
      margin-right: var(--cc-sp-4);

      & input {
        height: var(--cc-height-32);
        border-radius: var(--cc-sp-8);
        padding: var(--cc-sp-8);
        width: 40px;
        background-color: var(--cc-bg-tertiary);
        border: none !important;
      }
    }
  }

  .buttons {
    display: flex;
    font-weight: var(--cc-weight-medium);
    button {
      display: flex;
      column-gap: var(--cc-sp-6);
      padding: var(--cc-sp-6);

      &:nth-child(1) {
        padding-right: var(--cc-sp-12);
      }
      &:nth-child(2) {
        padding-left: var(--cc-sp-12);
      }
    }
  }
}

.button {
  padding: 0 !important;

  svg {
    height: var(--cc-size-16);
    width: var(--cc-size-16);
    color: var(--cc-text-secondary-subtle);
  }
}

.widgetCard {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--cc-bg-secondary);
  border: solid 1px var(--cc-border-primary);
  border-radius: var(--cc-sp-12) !important;
  min-width: 646px;
  width: 646px;
  min-height: 440px;

  .header,
  .filterHeader {
    border-bottom: solid 1px var(--cc-border-primary);
    padding: var(--cc-sp-6);
    padding-left: var(--cc-sp-16);

    .title {
      line-height: var(--cc-height-32);
      display: flex;
      justify-content: space-between;
    }

    .description {
      color: var(--cc-text-secondary);
    }
  }

  .filtrationChipWrapper {
    padding: var(--cc-sp-12) var(--cc-sp-16);
    border-bottom: solid 1px var(--cc-border-primary);
    display: flex;
    flex-wrap: wrap;
    column-gap: var(--cc-sp-8);
  }

  .state {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--cc-sp-16);
    font-weight: var(--cc-weight-medium);
    min-height: 376px;

    .infoContainer,
    .errorContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--cc-sp-8);

      & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--cc-sp-4);

        p:nth-child(2) {
          color: var(--cc-text-secondary);
        }
      }
    }

    .errorContainer {
      color: var(--cc-text-danger);

      svg {
        height: var(--cc-size-20);
        width: var(--cc-size-20);
        color: var(--cc-icon-danger);
      }
    }
  }
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--cc-sp-6);
}

.amountContainer {
  background: var(--cc-surface-info-subtle-hover);
  border: 1px solid var(--cc-text-brand-green);
  padding: var(--cc-sp-12);
  border-radius: var(--cc-sp-8);
}

.amountText {
  color: var(--cc-text-brand-green);
}
